import { cn } from '@/lib/utils';
import { MentionList } from '@/molecules/MentionList';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import { ReactRenderer, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

const useTipTapEditor = ({
  placeholder,
  className,
  errorMessage,
}: {
  placeholder?: string;
  errorMessage?: string;
  className?: string;
}) => {
  const editor = useEditor({
    immediatelyRender: false,
    extensions: [
      StarterKit.configure({ bold: false, italic: false }),
      Placeholder.configure({
        placeholder,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        deleteTriggerWithBackspace: true,
        suggestion: {
          render: () => {
            let reactRenderer: ReactRenderer;

            return {
              onStart: (props) => {
                reactRenderer = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor,
                });
              },

              onUpdate(props) {
                reactRenderer?.updateProps(props);
              },

              onKeyDown(props) {
                if (props.event.key === 'Escape') {
                  reactRenderer?.destroy();
                  return true;
                }

                return (reactRenderer?.ref as any)?.onKeyDown(props);
              },

              onExit() {
                reactRenderer.destroy();
              },
            };
          },
        },
      }),
    ],
    editorProps: {
      attributes: {
        class: cn(
          'mx-auto focus:outline-none h-full bg-black w-full duration-500 ease-in-out overflow-y-auto max-h-[152px] !min-h-9 rounded-sm border border-gray-brand2 bg-white px-3 py-1 text-sm transition text-black-brand file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-brand9 focus-visible:outline-none focus-visible:ring-0 focus-visible:border-purple-brand disabled disabled:bg-gray-brand4',
          className,
          errorMessage && 'border-opal-brand5 focus-visible:border-opal-brand5'
        ),
      },
    },
  });

  const value =
    editor?.getText({
      blockSeparator: '\n',
    }) || '';

  return { editor, value };
};

export default useTipTapEditor;
