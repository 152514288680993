import { CommentIcon, EyeIcon, HeartIcon, RewardIcon, SaveIcon } from '@/components/icons';
import ShareButton from '@/components/share';
import { PostType } from '@/types';
import React from 'react';

type Props = {
  onClick?: (action: string) => void;
  data?: Partial<PostType>;
  hideLike?: boolean;
  hideComment?: boolean;
  hideView?: boolean;
  hideShare?: boolean;
  hideReply?: boolean;
  hideSave?: boolean;
  likeProcessing?: boolean;
};

const PostFooter: React.FC<Props> = ({
  onClick,
  data,
  hideComment,
  hideLike,
  hideReply,
  hideShare,
  hideView,
  hideSave,
  likeProcessing,
}) => {
  return (
    <div className="flex flex-row items-center justify-between my-2">
      <div className="flex gap-2 md:gap-8">
        {!hideLike && (
          <div className="flex items-center gap-1 text-sm md:gap-2">
            <button
              disabled={!data?.hasJoinedCommunity || likeProcessing}
              className={!data?.hasJoinedCommunity ? 'cursor-not-allowed flex' : ' flex'}
              title={!data?.hasJoinedCommunity ? 'join community to like post' : ''}
              onClick={(e) => {
                e.stopPropagation();
                onClick?.('like');
              }}
            >
              <HeartIcon filled={data?.isLiked} />
            </button>
            <span>{data?.likeCount ?? 0}</span>
          </div>
        )}
        {!hideComment && (
          <div className="flex items-center gap-1 text-sm md:gap-2">
            <button onClick={() => onClick?.('comment')}>
              <CommentIcon />
            </button>
            <span>{data?.commentCount ?? 0}</span>
          </div>
        )}
        {!hideView && (
          <div className="flex items-center gap-1 text-sm md:gap-2">
            <button className="flex cursor-default" onClick={() => onClick?.('view')}>
              <EyeIcon />
            </button>
            <span>{data?.viewCount ?? 0}</span>
          </div>
        )}

        {!hideShare && (
          <ShareButton
            disabled={!data?.allowShare}
            path={`/post/${data?.slug}`}
            count={data?.shareCount ?? 0}
          />
        )}

        {!hideReply && (
          <div className="flex items-center gap-1 text-sm md:gap-2">
            <button className="flex cursor-default" onClick={() => onClick?.('reply')}>
              Reply
            </button>
          </div>
        )}
      </div>
      <div className="flex gap-2 md:gap-8">
        {!hideSave && (
          <div className="flex gap-1 text-sm md:gap-2">
            <button className="flex cursor-default" onClick={() => {}}>
              {/* TODO: should change selected when save is implemented */}
              <SaveIcon selected={false} />
            </button>
            <span>{data?.reportCount}</span>
          </div>
        )}
        <div className="flex gap-1 text-sm md:gap-2">
          <button className="flex cursor-default" onClick={() => {}}>
            <RewardIcon />
          </button>
          <span>{data?.updateCount}</span>
        </div>
      </div>
    </div>
  );
};

export default PostFooter;
