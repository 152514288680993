import { getCommentsByPostId } from '@/server/actions/posts.actions';
import { CommentType, PostCommentParamsType } from '@/types';
import { useInfiniteQuery } from '@tanstack/react-query';

const useGetCommentsByContentId = ({
  PageSize = 15,
  PageNumber,
  enabled,
  ...rest
}: PostCommentParamsType & { enabled?: boolean }) => {
  const { data, ...other } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['GetCommentsByContentId', { PageSize, ...rest }],
    queryFn: ({ pageParam }) => getCommentsByPostId({ PageSize, PageNumber: pageParam, ...rest }),
    getNextPageParam: (lastPage: any, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
    enabled,
  });

  const payload = data?.pages.reduce((acc, page) => [...acc, ...page.data], []);

  return { ...other, data: payload, total: data?.pages[0]?.totalCount ?? '0' };
};

export default useGetCommentsByContentId;
