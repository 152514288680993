'use client';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { Button } from '@/components/atom/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/atom/dropdown-menu';
import { EmojiOutline, ImageGallery, SendPost } from '@/components/icons';
import { Loader2, Trash2 } from 'lucide-react';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import React from 'react';
import { EditorContent } from '@tiptap/react';
import useTipTapEditor from '@/hooks/use-tiptap-editor';
import useLoadPreview from '@/hooks/use-load-preview';
import MetadataPreviewLoader from '@/components/metadata-preview-loader';
import MetadataPreview from '@/components/metadata-preview';
import { CreateCommentPayloadType } from '@/types';
import useCreatePostCommentMutation from '@/hooks/react-query/posts/mutations/useCreatePostCommentMutation';
import { useQueryClient } from '@tanstack/react-query';
import Avatar from '@/components/atom/avatar';

const PostCommentForm = ({
  placeholder = '',
  onSubmit,
  id,
  parentType,
}: {
  onSubmit?: (p: Omit<CreateCommentPayloadType, 'contentId' | 'parentType'>) => void;
  placeholder?: string;
  id: number;
  parentType: number;
}) => {
  const queryClient = useQueryClient();
  const session = useSession();
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [values, setValues] = React.useState<{
    comment: string;
    image: File | null;
    imageSrc: string;
  }>({
    comment: '',
    image: null,
    imageSrc: '',
  });

  const { editor, value } = useTipTapEditor({ placeholder });
  const { loading, metadata, handleClose } = useLoadPreview(value);
  const createCommentMutation = useCreatePostCommentMutation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload: CreateCommentPayloadType = {
      comment: value,
      contentId: id,
      allowComments: true,
      allowLikes: true,
      allowShare: true,
      parentType,
    };
    if (values.image) {
      payload['image'] = {
        name: values.image?.name ?? '',
        contentType: values?.image?.type ?? '',
        base64: values?.imageSrc?.split(',')[1] ?? '',
      };
    }
    onSubmit?.(payload);
    createCommentMutation.mutate(payload, {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ['GetCommentsByContentId'] });
        queryClient.invalidateQueries({
          queryKey: ['GetAllPosts', { PageSize: 15, UserId: session?.data?.user?.userId }],
        });
        editor?.commands.clearContent();
      },
    });
  };

  const handleChange = (e: string | File | null, name: string) => {
    if (name === 'image') {
      const reader = new FileReader();
      reader.onload = (ev) => {
        const imageSrc = ev.target?.result as string;
        setValues((prev) => ({ ...prev, [name]: e as File, imageSrc }));
      };
      reader.readAsDataURL(e as File);
    } else {
      setValues((prev) => ({ ...prev, [name]: e }));
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="p-2 my-4 rounded-md bg-pink-brand-muted">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 my-2">
            <Avatar
              fallbackText={`${session?.data?.user?.name?.split(' ')?.[0]?.[0] ?? ''} ${
                session?.data?.user?.name?.split(' ')?.[1]?.[0] ?? ''
              }`}
              className="h-10 w-10"
              src={session?.data?.user?.avatarUrl ?? ''}
              isOnline
            />
            <p className="text-sm">{session?.data?.user?.name}</p>
          </div>
          <div className="flex items-center justify-end gap-3">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" className="p-1 rounded-full h-fit w-fit">
                  <EmojiOutline />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="p-0 border-none shadow-none" align="start">
                <DropdownMenuItem>
                  <Picker
                    data={data}
                    onEmojiSelect={(emoji: { native: string }) =>
                      editor?.commands.insertContent(emoji.native)
                    }
                    emojiSize={16}
                    theme="light"
                  />
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                fileInputRef?.current?.click();
              }}
            >
              <input
                id="image"
                name="image"
                style={{ display: 'none' }}
                type="file"
                ref={fileInputRef}
                onChange={(e) => {
                  handleChange(e.target?.files?.[0] as File, 'image');
                }}
              />
              <ImageGallery />
            </button>
          </div>
        </div>
        <div className="flex items-end w-full gap-2 my-2">
          <div className="w-full">
            {loading ? (
              <MetadataPreviewLoader />
            ) : (
              metadata && (
                <MetadataPreview
                  className="bg-gray-brand8"
                  handleClose={handleClose}
                  data={metadata}
                />
              )
            )}

            <EditorContent
              editor={editor}
              disabled={createCommentMutation.isPending}
              // className={cn(
              //   'w-full duration-500 ease-in-out overflow-y-auto max-h-[152px] min-h-9 rounded-sm border border-gray-brand2 bg-white px-3 py-1 text-sm transition text-black-brand file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-brand9 focus-visible:outline-none focus-visible:ring-0 focus-visible:border-purple-brand disabled disabled:bg-gray-brand4'
              // )}
            />
          </div>
          <Button
            type="submit"
            color="primary"
            size="sm"
            disabled={value.trim().length === 0 || createCommentMutation.isPending}
            className="gap-2 flex items-center disabled:!bg-gray-brand2 disabled:!text-white"
          >
            {createCommentMutation.isPending ? (
              <Loader2 className="animate-spin" size={18} />
            ) : (
              <>
                <SendPost /> Post
              </>
            )}
          </Button>
        </div>
        {values.image && (
          <div className="relative w-[171px] h-24 overflow-hidden shadow-lg">
            <Image src={values.imageSrc || ''} alt="image" fill className="object-cover" />
            <Button
              variant="ghost"
              size="icon"
              className="absolute bottom-0 right-0 h-fit w-fit p-1"
              onClick={() => {
                setValues((prev) => ({ ...prev, image: null, imageSrc: '' }));
                if (fileInputRef.current) {
                  fileInputRef.current.value = ''; // Reset the file input
                }
              }}
            >
              <Trash2 size={16} className="text-gray-brand9" />
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};

export default PostCommentForm;
