import React from 'react';
import useAppStore from '@/hooks/use-app-store';
import { ShareIcon } from '../icons';

const ShareButton = ({
  count = 500,
  disabled = false,
  path,
  hideCount,
  title = 'Share this post',
}: {
  hideCount?: boolean;
  path?: string;
  disabled?: boolean;
  count?: number;
  title?: string;
}) => {
  const openShareModal = useAppStore((state: any) => state.openShareModal);

  return (
    <div className="flex gap-2 items-center text-sm">
      <button
        className="h-fit w-fit flex p-0"
        onClick={() => openShareModal({ title, path })}
        disabled={disabled}
      >
        <ShareIcon />
      </button>
      {!hideCount && <span>{count}</span>}
    </div>
  );
};

export default ShareButton;
