import { toggleLike } from '@/server/actions/posts.actions';
import { PostType, ToggleInputType } from '@/types';
import { InvalidateQueryFilters, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { useToast } from '@/hooks/use-toast';
import { SortOrderEnum, SortPostByEnum } from '@/utils/enums';

const useToggleLikeMutation = () => {
  const session = useSession();
  const queryKey = [
    'GetUserFeedPosts',
    {
      PageSize: 15,
      UserId: session?.data?.user?.userId,
      SortBy: SortPostByEnum.Default,
      SortOrder: SortOrderEnum.Descending,
    },
  ];
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ contentId, parentType, like }: ToggleInputType) =>
      toggleLike({ contentId, parentType, like }),

    onMutate: async ({ contentId, like }) => {
      await queryClient.cancelQueries({ queryKey });
      const previousPosts = queryClient.getQueryData(queryKey);
      queryClient.setQueryData<any>(queryKey, (old: any) => {
        if (!old) return old;
        const newOldPages = old.pages?.map((page: any) => ({
          ...page,
          data: page?.data?.map((post: PostType) =>
            post.id === contentId
              ? { ...post, likeCount: post.likeCount + (!like ? -1 : 1), isLiked: like }
              : post
          ),
        }));
        return { ...old, pages: newOldPages };
      });

      // Return a context object with the snapshotted value
      return { previousPosts };
    },

    onSuccess: (res, variables, context) => {
      if (res.success) {
        toast({
          variant: 'success',
          description: 'Post like success',
        });
        queryClient.invalidateQueries(queryKey as InvalidateQueryFilters);
      } else {
        // Rollback the optimistic update if mutation fails
        if (context?.previousPosts) {
          queryClient.setQueryData(queryKey, context.previousPosts);
        }
        toast({
          variant: 'destructive',
          description: res.message,
        });
      }
    },
  });
};

export default useToggleLikeMutation;
