export const CLIENT_KEY = process.env.NEXT_PUBLIC_CLIENT_KEY;

export const APP_DOMAIN = process.env.AUTH_URL || process.env.NEXT_PUBLIC_DOMAIN_URL;

export const ONBOARD_KEY = process.env.ONBOARD_KEY;
export const PAYSTACK_PUBLIC_KEY = process.env.PAYSTACK_PUBLIC_KEY;

export const FUSION_API = {
  apiMicroserviceUserSecurity: process.env.NEXT_PUBLIC_USER_SECURITY_BASE_URL,
  apiMicroserviceUserSettings: process.env.NEXT_PUBLIC_USER_SETTINGS_BASE_URL,
  apiMicroserviceAdmin: process.env.NEXT_PUBLIC_ADMIN_BASE_URL,
  apiMicroserviceOnboarding: process.env.NEXT_PUBLIC_ONBOARDING_BASE_URL,
  apiKey: process.env.NEXT_PUBLIC_FUSION_API_KEY,
  endpoint: process.env.NEXT_PUBLIC_FUSION_ENDPOINT,
  communitiesApi: process.env.NEXT_PUBLIC_COMMUNITIES_BASE_URL,
  apiMicroServiceWallet: process.env.NEXT_PUBLIC_WALLETS_BASE_URL,
  apiMicroServiceCommunity: process.env.NEXT_PUBLIC_COMMUNITIES_BASE_URL,
};

export const FIREBASE_API = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.NEXT_PUBLIC_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  callbackUrl: process.env.NEXT_PUBLIC_AUTH0_CALLBACK_URL,
};

export const USER_ROLES = {
  admin: 2,
  user: 1,
};
